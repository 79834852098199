import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { InviteUserEntryPoint, TUIAssignments, TUIPersona, TUISetup } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import PageContainer from 'components/v2/PageContainer';
import { ROUTES } from 'constants/routes';
import { AllowedDomains } from 'constants/v2/ApplicationConstants';
import ApplicationContext from 'context/ApplicationContext';
import useGetOktaUsers from 'hooks/useGetOktaUsers';
import usePersonaDetails from 'hooks/v2/usePersonaDetails';
import useUserManagement from 'hooks/v2/useUserManagement';
import useFormatDataStructure from 'hooks/v2/utils/useFormatDataStructure';
import useUtilities from 'hooks/v2/utils/useUtilities';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserManagementService from 'services/v2/useUserManagementService';
import { TInternalUserProfile, TInviteUserFormData } from 'types/v2/userManagement';

let internalUsersList: Array<{ label: string; value: string; internalUserProfile: TInternalUserProfile }> = [];
const InviteUsersPage = () => {
    const [loadingAssignments, setLoadingAssignments] = useState(false);
    const [assignments, setAssignments] = useState<TUIAssignments | null>(null);
    const [setUp, setSetup] = useState<TUISetup>();
    const [loadingConfig, setLoadingConfig] = useState(false);
    const [loadingReports, setLoadingReports] = useState(false);
    const [persona, setPersona] = useState<TUIPersona | null>(null);
    const [loadingPersonalInfo, setLoadingPersonalInfo] = useState(true);
    const [loadingPersonaTypes, setLoadingPersonaTypes] = useState(true);
    const [countryCode, setCountryCode] = useState('');
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const { userPersona, setShowToast } = useContext(ApplicationContext);
    const { fetchPersonaDetails, personaTypes } = usePersonaDetails(oktaAuth);
    const { initInviteUserSetup } = useUserManagementService();
    const { inviteUser } = useUserManagement(oktaAuth);
    const { mapInviteUserReqPayload } = useFormatDataStructure();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const { fetchOktaUsersList } = useGetOktaUsers(oktaAuth);
    const { debounce } = useUtilities();
    const fetchPersona = async () => {
        await fetchPersonaDetails(String(userPersona));
        setLoadingPersonaTypes(false);
    };

    useEffect(() => {
        fetchPersona();
    }, [userPersona]);

    const initialFunction = async () => {
        const { setUp: options } = await initInviteUserSetup(persona.sections, persona.type, persona.languages);
        setSetup(options);
        setLoadingReports(false);
        setLoadingPersonalInfo(false);
        setLoadingAssignments(false);
    };

    useEffect(() => {
        persona && persona.sections && initialFunction();
    }, [persona]);

    const onSubmit = async (formData: TInviteUserFormData) => {
        setLoadingSubmit(true);
        try {
            const inviteUserPayload = mapInviteUserReqPayload(formData, persona, internalUsersList, countryCode);
            const inviteUserResponse = await inviteUser(inviteUserPayload);
            if (inviteUserResponse && inviteUserResponse.ErrorCode === 0) {
                setShowToast({ toastMessage: 'User Invited Successfully!' });
                navigate(`${ROUTES.ROOT}${ROUTES.USER_MANAGEMENT}`);
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Error Occurred!' });
            }
        } catch (error) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Failed to invite user!' });
            console.error('Error occurred while inviting user:', error);
        } finally {
            setLoadingSubmit(false);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadOptions = useCallback(
        debounce(
            (inputValue: string, callback: (options: Array<{ label: string; value: string; internalUserProfile: TInternalUserProfile }>) => void) => {
                fetchOktaUsersList(inputValue).then((options) => {
                    const filteredOptions = options.filter((option) => {
                        return AllowedDomains.some((domain) => option.value.endsWith(domain));
                    });
                    internalUsersList = filteredOptions;
                    callback(filteredOptions);
                });
            },
            500
        ),
        []
    );
    const handleCountrySelect = (countryCode: string) => {
        setCountryCode(countryCode);
    };
    return (
        <PageContainer>
            <InviteUserEntryPoint
                isLoadingTheAssignments={loadingAssignments}
                assignments={assignments}
                setUp={setUp}
                isLoadingPersonalInfo={loadingPersonalInfo}
                isLoadingReports={loadingReports}
                onCancel={() => {
                    navigate(`${ROUTES.ROOT}${ROUTES.USER_MANAGEMENT}`);
                }}
                thePersonaTypes={personaTypes ?? []}
                thePersona={persona}
                loadOptions={loadOptions}
                onSubmitHandler={onSubmit}
                onTypeChanged={(sel) => {
                    if (sel) {
                        setPersona(sel);
                        setSetup((setup) => ({ ...setup, assignments }));
                    } else {
                        setLoadingConfig(false);
                        setPersona(null);
                    }
                }}
                isLoadingPersonaTypes={loadingPersonaTypes}
                loadingConfig={loadingConfig}
                loadingSubmit={loadingSubmit}
                personaCount={personaTypes.length}
                selectedCountry={handleCountrySelect}
            />
        </PageContainer>
    );
};

export default InviteUsersPage;
